body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.btn {
  font-size: 0.9rem !important;
  font-weight: 500 !important;
}

.container {
  max-width: 900px !important;
}

.vote-btn {
  font-size: 3rem;
  line-height: 2.5rem;
  cursor: pointer;
  border-radius: 4px;
  text-align: center;
  padding: 3rem 0rem;
  width: 48%;
  transition: all 200ms;
}

.reverse {
  display: flex;
  flex-direction: row-reverse;
}


.vote-down {
  background-color: rgba(255, 0, 55, 0.3);
  color: rgb(255, 0, 106);
}

.vote-down:hover {
  background-color: rgba(255, 0, 55, 0.5);
}

.vote-up {
  background-color: rgba(0, 202, 0, 0.3);
  color: rgb(0, 177, 74);
}

.vote-up:hover {
  background-color: rgba(0, 202, 0, 0.5);
}

input[type="radio"] {
  display: none;
}

.survey-btn {
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent !important;
  color: #dddddd;
  border: none;
  font-size: 1.75rem !important;
  padding: 0rem 0;
  margin-bottom: 0.5rem;
  height: 2rem;
  width: 100%;
}

.main-wrapper {
  overflow-y: scroll;
}

.submit-button {
  max-width: 220px;
  margin: 0 auto;
  font-size: 16px !important;
}

.reverse > .survey-btn:hover ~ .survey-btn,
.reverse > .survey-btn:hover,
.reverse > .survey-btn:active ~ .survey-btn,
.reverse > .survey-btn:active,
.reverse > .survey-btn.active ~ .survey-btn,
.reverse > .survey-btn.active,
.reverse > .survey-btn:focus ~ .survey-btn,
.reverse > .survey-btn:focus  {
  background-color: transparent !important;
  color: gold;
}

.survey-btn > svg {
  transition: transform 100ms;
}

.survey-btn:hover > svg {
  position: relative;
  top: -4px;
  transform: rotate(6deg) translateY(0px); 
}

.review-link {
  cursor: pointer;
  border-radius: 4px;
  text-align: center;
  background-color: rgba(0,0,0,0.05);
  margin-bottom: 6px;
  padding: 1rem;
  transition: all 200ms;
}

.review-link:hover {
  background-color: rgba(0,0,0,0.2);
}

.survey-btn-group-wrapper{
  max-width: 250px;
  margin: 0 auto;
}

.survey-btn-group{
  max-width: 220px;
  margin: 0 auto;
}

.modal-body {
  padding: 1rem;
}

.review-link > img {
  max-width: 200px;
  max-height: 65px
}

.modal-footer {
  padding: 0 !important;
}

.modal-footer>* {
  margin: 0 !important;
}

.modal-footer-link {
  border-radius: 0 !important;
  background-color: rgba(0,0,0,.1) !important;
  border: none !important;
  color: grey !important;
  flex-grow: 1;
  padding: 1rem 0.5rem !important;
  transition: all 200ms;
}

.modal-footer-link:hover {
  background-color: rgba(0,0,0,.2) !important;
}

.btn-secondary {
  background-color: #fff;
  box-shadow: 0 0 0 1px rgb(42 47 69 / 10%), 0 2px 5px 0 rgb(42 47 69 / 8%), 0 1px 1.5px 0 rgb(0 0 0 / 7%), 0 1px 2px 0 rgb(0 0 0 / 8%), 0 0 0 0 transparent;
  color: #4f566b;
  cursor: pointer;
  font-size: 16px !important; 
  font-weight: 500;
  line-height: 1.2;
  text-align: left;
  border: transparent;
}